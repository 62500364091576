exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-om-oss-js": () => import("./../../../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-projekt-index-js": () => import("./../../../src/pages/projekt/index.js" /* webpackChunkName: "component---src-pages-projekt-index-js" */),
  "component---src-pages-projekt-sanity-project-slug-current-js": () => import("./../../../src/pages/projekt/{sanityProject.slug__current}.js" /* webpackChunkName: "component---src-pages-projekt-sanity-project-slug-current-js" */),
  "component---src-pages-ta-kontakt-js": () => import("./../../../src/pages/ta-kontakt.js" /* webpackChunkName: "component---src-pages-ta-kontakt-js" */),
  "component---src-pages-vara-losningar-index-js": () => import("./../../../src/pages/vara-losningar/index.js" /* webpackChunkName: "component---src-pages-vara-losningar-index-js" */),
  "component---src-pages-vara-losningar-tillval-sanity-add-on-slug-current-js": () => import("./../../../src/pages/vara-losningar/tillval/{sanityAddOn.slug__current}.js" /* webpackChunkName: "component---src-pages-vara-losningar-tillval-sanity-add-on-slug-current-js" */)
}

